var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.profilePage && _vm.data.properties.filed_content != 'Hide')?_c('div',{style:(_vm.getElementStyle)},[_c('el-row',{attrs:{"align":"middle"}},[(!_vm.data.properties.hideLabel)?_c('el-col',{style:(_vm.computedStyles),attrs:{"span":_vm.showLabel && !_vm.isDefalutPos ? 4 : _vm.data.description ? 20 : 24}},[(_vm.hasLabel && _vm.data.properties.filed_content != 'Hide')?_c('label',{class:{ 'highlighted-label': _vm.highlight },style:(_vm.getLabelStyles),attrs:{"for":"text"}},[_vm._v(_vm._s(_vm.label||_vm.data.label))]):_vm._e(),(_vm.data.validations.required  && _vm.hasLabel && !_vm.isView)?_c('span',{staticStyle:{"color":"red","font-weight":"bold","margin-left":"5px","margin-right":"5px"}},[_vm._v("*")]):_vm._e(),_c('span',[(_vm.data.description)?_c('el-popover',{attrs:{"placement":"top-start","trigger":"hover","content":_vm.data.description}},[_c('i',{staticClass:"el-icon-info",attrs:{"slot":"reference"},slot:"reference"})]):_vm._e()],1)]):_c('el-col',{ref:"colRef",style:(_vm.computedStyles),attrs:{"span":_vm.showLabel ? 4 : _vm.data.description ? 20 : 24}},[(_vm.hasLabel)?_c('span',{staticClass:"transparentcol"}):_vm._e(),(_vm.data.validations.required  && _vm.hasLabel && !_vm.isView)?_c('span',{staticStyle:{"color":"red","font-weight":"bold","margin-left":"5px","margin-right":"5px"}},[_vm._v("*")]):_vm._e()]),_c('el-col',{attrs:{"span":_vm.showLabel
      &&
      !_vm.isDefalutPos
      ?
      12
      :
      24}},[_c('div',{staticClass:"vue-phone-validator"},[(!_vm.isView && _vm.data.properties.filed_content != 'Hide')?_c('p',[(_vm.data.countryDisable == true)?_c('VuePhoneNumberInput',{class:{ 'disabled-div': _vm.checkReadonly() },style:(_vm.getStyle),attrs:{"required":"","onlyCountries":[_vm.data.defaultCountry],"default-country-code":_vm.data.defaultCountry,"disabled":_vm.checkReadonly() || _vm.readonly(),"readonly":_vm.readonly()},on:{"update":_vm.onUpdate},model:{value:(_vm.form[_vm.data.key]),callback:function ($$v) {_vm.$set(_vm.form, _vm.data.key, $$v)},expression:"form[data.key]"}}):_c('VuePhoneNumberInput',{class:{ 'disabled-div': _vm.checkReadonly() },style:(_vm.getStyle),attrs:{"required":"","disabled":_vm.checkReadonly() || _vm.readonly(),"default-country-code":_vm.form[`${_vm.data.key}_code`]
                ? _vm.form[`${_vm.data.key}_code`].countryCode
                : _vm.data.defaultCountry,"readonly":_vm.readonly()},on:{"update":_vm.onUpdate},model:{value:(_vm.form[_vm.data.key]),callback:function ($$v) {_vm.$set(_vm.form, _vm.data.key, $$v)},expression:"form[data.key]"}})],1):_vm._e()]),(_vm.isView)?_c('p',{style:(_vm.getStyle)},[(_vm.form[_vm.data.key])?[_vm._v("+ "),(_vm.form[_vm.data.key + '_code'] &&_vm.form[_vm.data.key + '_code'].countryCallingCode)?_c('span',[_vm._v(" "+_vm._s(_vm.form[_vm.data.key + "_code"].countryCallingCode))]):_vm._e(),_vm._v("    "),(_vm.form[_vm.data.key + '_code'] &&_vm.form[_vm.data.key + '_code'].formatNational)?_c('span',[_vm._v(_vm._s(_vm.form[_vm.data.key + '_code'].formatNational))]):_c('span',[_vm._v(" "+_vm._s(_vm.form[_vm.data.key])+" ")])]:[_vm._v("-")]],2):_vm._e()])],1)],1):_c('div',[(_vm.isView)?_c('p',{style:(_vm.getStyle)},[(_vm.form[_vm.data.key])?[_vm._v("+ "),(
        _vm.form[_vm.data.key + '_code'] &&
        _vm.form[_vm.data.key + '_code'].countryCallingCode
        )?_c('span',[_vm._v(_vm._s(_vm.form[_vm.data.key + "_code"].countryCallingCode))]):_vm._e(),(
          _vm.form[_vm.data.key + '_code'] && _vm.form[_vm.data.key + '_code'].countryCode
        )?_c('span',[_vm._v("("+_vm._s(_vm.form[_vm.data.key + "_code"].countryCode)+") ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.form[_vm.data.key]))]:[_vm._v("-")]],2):_c('div',{staticClass:"vue-phone-validator"},[(_vm.data.properties.filed_content != 'Hide')?_c('p',[(_vm.data.countryDisable == true)?_c('VuePhoneNumberInput',{class:{ 'disabled-div': _vm.checkReadonly() },style:(_vm.getStyle),attrs:{"required":"","onlyCountries":[_vm.data.defaultCountry],"default-country-code":_vm.data.defaultCountry,"disabled":_vm.checkReadonly() || _vm.readonly(),"size":"mini","readonly":_vm.readonly()},on:{"update":_vm.onUpdate},model:{value:(_vm.form[_vm.data.key]),callback:function ($$v) {_vm.$set(_vm.form, _vm.data.key, $$v)},expression:"form[data.key]"}}):_c('VuePhoneNumberInput',{class:{ 'disabled-div': _vm.checkReadonly() },style:(_vm.getStyle),attrs:{"required":"","disabled":_vm.checkReadonly() || _vm.readonly(),"default-country-code":_vm.form[`${_vm.data.key}_code`]
            ? _vm.form[`${_vm.data.key}_code`].countryCode
            : _vm.data.defaultCountry,"size":"mini","readonly":_vm.readonly()},on:{"update":_vm.onUpdate},model:{value:(_vm.form[_vm.data.key]),callback:function ($$v) {_vm.$set(_vm.form, _vm.data.key, $$v)},expression:"form[data.key]"}})],1):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }